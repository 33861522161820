import GameHeader from "./GameHeader";
import GameFooter from "./GameFooter";
import GameLeftContent from "./GameLeftContent";
import BigLoader from "../../loaders/BigLoader";
import SolveCaseModal from "../SolveCase/SolveCaseModal";
import { structureFilesIntoFolders } from "../../../utils/HelpFunctions";
import GameLandingPage from "./GameLandingPage";
import {
  CaseGamePageContent,
  CaseGamePageMain,
  CaseGamePageWrapper,
} from "./styles";
import {
  FileData,
  FolderStructureType,
  InvitationType,
  MyCaseAccessType,
  SuspectType,
} from "../../../utils/Interfaces/Game";

import MicRoundedIcon from "@mui/icons-material/MicRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import GameFail from "../SolveCase/ModalCaseResult/GameFail";
import GameMainContent from "./GameMainContent";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AddTeamMembersModal from "../AddTeamMembersModal";
import TooSmallScreen from "./TooSmallScreen";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { useLocation } from "react-router-dom";
import { getData, postData } from "../../../utils/endpoints/api";

var AWS = require("aws-sdk");
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
var s3 = new AWS.S3();

const foldersStructure: FolderStructureType = {
  communications: { icon: TextsmsRoundedIcon, text: "Comunicações" },
  crime_scene_analysys: {
    icon: VideocamRoundedIcon,
    text: "Análise da cena do crime",
  },
  interviews: { icon: NewspaperRoundedIcon, text: "Entrevistas" },
  other_proofs: { icon: MicRoundedIcon, text: "Outras provas" },
  suspect_profiles: {
    icon: NewspaperRoundedIcon,
    text: "Perfis dos suspeitos",
  },
  testemonials: { icon: VideocamRoundedIcon, text: "Testemunhos" },
};

export default function CaseGamePage(props: { caseData: MyCaseAccessType }) {
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const location = useLocation();
  const isSharedCaseGame = location.pathname.startsWith("/shared-case");
  const { caseData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [gameLadingPage, setGameLandingPage] = useState(true);
  const [addTeamMembersModal, setAddTeamMembersModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [gameFail, setGameFail] = useState(false);
  const [files, setFiles] = useState<{ [key: string]: FileData[] }>({});
  const [activeFolder, setActiveFolder] = useState(
    Object.keys(foldersStructure)[0]
  );
  const [reportSubmitted, setReportSubmitted] = useState<SuspectType | null>(
    null
  );
  const queryClient = useQueryClient();
  const finishCaseMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("my-case/finish-case", params, body),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["caseAccess"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao terminar o caso. Tenta mais tarde!",
      ]);
    },
  });
  const { data: invitations, error: invitationsError } = useQuery<
    InvitationType[],
    Error
  >({
    queryKey: ["invitations"],
    queryFn: () =>
      getData("my-case/team-members", {
        userId: user?.id!,
        orderItemId: caseData?.orderItemId.toString(),
      }),
  });

  useEffect(() => {
    if (invitationsError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter os membros da equipa. Tenta mais tarde!",
      ]);
    }
  }, [invitationsError]);

  useEffect(() => {
    if (caseData?.status === "ACTIVE") {
      listS3Bucket();
    }
  }, [caseData]);

  const finishCase = async () => {
    try {
      await finishCaseMutation.mutateAsync({
        params: {
          caseId: caseData?.caseId.toString()!,
          orderItemId: caseData?.orderItemId.toString()!,
        },
        body: {},
      });
    } catch (error) {
      console.error("Error terminating case:", error);
    }
  };

  useEffect(() => {
    if (reportSubmitted?.guiltStatus === 1) {
      finishCase();
    } else if (reportSubmitted?.guiltStatus === 0) {
      setGameFail(true);
    } else {
      setGameFail(false);
    }
  }, [reportSubmitted]);

  const handleModalOpen = () => setModalOpen(!modalOpen);

  const handleReportSubmitted = (selectedSuspect: SuspectType) => {
    handleConfirmModalOpen();
    handleModalOpen();
    setReportSubmitted(selectedSuspect);
  };

  const handleOpenCase = () => setGameLandingPage(false);
  const handleConfirmModalOpen = () => setConfirmModalOpen(!confirmModalOpen);
  const handleAddTeamMemberModal = () =>
    setAddTeamMembersModal(!addTeamMembersModal);
  const handleResetReportSubmitted = () => setReportSubmitted(null);

  async function listS3Bucket() {
    s3.listObjects(
      { Bucket: caseData?.path, Prefix: "game/" },
      async (err: { stack: any }, data: any) => {
        if (err) console.log(err, err.stack);
        else {
          const filesWithUrls = await Promise.all(
            data?.Contents?.map(async (file: any) => {
              const signedUrl = await s3.getSignedUrlPromise("getObject", {
                Bucket: caseData?.path,
                Key: file.Key,
                Expires: 3600,
                ResponseContentDisposition: "inline",
              });
              return {
                ...file,
                signedUrl,
              };
            })
          );
          const structuredRepo = structureFilesIntoFolders(filesWithUrls);
          setFiles(structuredRepo);
        }
      }
    );
  }

  const aggregatedLoading = finishCaseMutation.isPending || isLoading;

  return aggregatedLoading ? (
    <BigLoader />
  ) : (
    <>
      <CaseGamePageWrapper>
        {gameLadingPage ? (
          <GameLandingPage
            caseData={caseData}
            handleOpenCase={handleOpenCase}
          />
        ) : gameFail ? (
          <GameFail
            caseData={caseData}
            reportSubmitted={reportSubmitted}
            handleResetReportSubmitted={handleResetReportSubmitted}
          />
        ) : (
          <Fragment>
            {!isSharedCaseGame && (
              <>
                <SolveCaseModal
                  suspects={caseData?.characters}
                  modalOpen={modalOpen}
                  confirmModalOpen={confirmModalOpen}
                  handleModalOpen={handleModalOpen}
                  handleConfirmModalOpen={handleConfirmModalOpen}
                  handleReportSubmitted={handleReportSubmitted}
                />
                {invitations && (
                  <AddTeamMembersModal
                    caseData={caseData}
                    modalOpen={addTeamMembersModal}
                    handleModalOpen={handleAddTeamMemberModal}
                  />
                )}
              </>
            )}
            <CaseGamePageMain>
              <GameHeader
                caseData={caseData}
                handleModalOpen={handleModalOpen}
                handleAddTeamMemberModal={handleAddTeamMemberModal}
              />
              <CaseGamePageContent>
                <GameLeftContent
                  handleModalOpen={handleModalOpen}
                  files={files}
                  setIsLoading={setIsLoading}
                  caseData={caseData}
                  activeFolder={activeFolder}
                  setActiveFolder={setActiveFolder}
                  foldersStructure={foldersStructure}
                />
                <GameMainContent
                  bucketUrl={caseData.bucketName}
                  files={files}
                  activeFolder={activeFolder}
                />
              </CaseGamePageContent>
              <GameFooter />
            </CaseGamePageMain>
          </Fragment>
        )}
      </CaseGamePageWrapper>
      <TooSmallScreen />
    </>
  );
}
