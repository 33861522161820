import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import {
  CaseCardToPurchaseWrapper,
  DiscountBox,
  Image,
  PriceBox,
} from "./styles";
import { Box, Typography } from "@mui/material";
import {
  BoldMediumText,
  BoldSmallText,
  MainButton,
  MediumText,
  SecondaryButton,
  SmallText,
  UnderlinedButton,
} from "../../utils/GlobalStyles";
import { CaseType } from "../../utils/Interfaces/Case";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { COLORS } from "../../assets/theme";
import { addItemToCart, removeItemFromCart } from "../CartItem/helpers";
import AddToCartMinusPlus from "../AddToCartMinusPlus";
import { deleteData, postData } from "../../utils/endpoints/api";
import LocalLoader from "../loaders/LocalLoader";
import RedeemIcon from "@mui/icons-material/Redeem";

function CaseCardToPurchase(caseItem: CaseType) {
  const { user, setCart, groupedCart, setWarningMessage } = useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const [numberCartItems, setNumberCartItems] = useState<number>(0);
  const navigate = useNavigate();

  const addCartItemMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("cart/add", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao adicionar o item ao carrinho. Tenta mais tarde!",
      ]);
    },
  });
  const removeCartItemMutation = useMutation({
    mutationFn: (params: Record<string, string>) =>
      deleteData("cart/remove", params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro ao remover o item do carrinho. Tenta mais tarde!",
      ]);
    },
  });

  useEffect(() => {
    const groupedCartItem = groupedCart?.find(
      (groupedCartItem) => caseItem.caseId === groupedCartItem.caseId
    );
    if (groupedCartItem) {
      setNumberCartItems(groupedCartItem.quantity);
    } else {
      setNumberCartItems(0);
    }
  }, [groupedCart]);

  const queryClient = useQueryClient();
  const handleAddItemToCart = async () => {
    addItemToCart({
      user: user,
      mutation: addCartItemMutation,
      setCart: setCart,
      item: caseItem,
    });
  };

  const handleBuyNow = async () => {
    handleAddItemToCart();
    navigate("/shopping-cart");
  };

  const handleRemoveItemToCart = async () => {
    const groupedCartItem = groupedCart?.find(
      (groupedCartItem) => caseItem.caseId === groupedCartItem.caseId
    );
    if (groupedCartItem) {
      removeItemFromCart({
        user: user,
        mutation: removeCartItemMutation,
        setCart: setCart,
        groupedItem: groupedCartItem,
      });
    }
  };

  const cartLoading =
    addCartItemMutation.isPending || removeCartItemMutation.isPending;

  return (
    <CaseCardToPurchaseWrapper>
      <NavLink to={`${caseItem.path}`}>
        <Image
          width={768}
          height={768}
          src={`images/cases/${caseItem.path}/main_horizontal.png`}
          alt={caseItem.name}
          style={{ marginBottom: "20px" }}
        />
      </NavLink>
      <Box sx={{ flex: "1" }}>
        <SmallText color={`${COLORS.redTwo} !important`}>
          Dificuldade: {caseItem.difficulty}/10
        </SmallText>
        <NavLink to={`${caseItem.path}`}>
          <BoldMediumText display='flex' gap={1} alignItems='center' my={1}>{caseItem.name} { caseItem.caseId === 2 && <BoldSmallText color='red !important'>NOVO!</BoldSmallText>}</BoldMediumText>
        </NavLink>
        <MediumText color={`${COLORS.grayFour} !important`}>
          {caseItem.teaserSummary}
        </MediumText>
        {caseItem.caseId !== 2 && (
          <PriceBox mt={1} mb={3}>
            {caseItem.discount > 0 && (
              <MediumText
                color={`${COLORS.redTwo} !important`}
                sx={{ textDecorationLine: "line-through" }}
              >
                €19.99
              </MediumText>
            )}
            <BoldMediumText color={`${COLORS.greenOne} !important`}>
              €
              {Math.round(caseItem.price * (1 - caseItem.discount) * 100) / 100}
            </BoldMediumText>
            {caseItem.discount > 0 && (
              <DiscountBox>
                <MediumText>-{caseItem.discount * 100}%</MediumText>
              </DiscountBox>
            )}
          </PriceBox>
        )}
      </Box>
      {cartLoading ? (
        <LocalLoader height="40" />
      ) : caseItem.caseId !== 2 ? (
        <>
          {numberCartItems > 0 ? (
            <AddToCartMinusPlus
              caseCartToBuy={true}
              quantity={numberCartItems}
              handleRemoveItemToCart={handleRemoveItemToCart}
              handleAddItemToCart={handleAddItemToCart}
            />
          ) : (
            <MainButton
              onClick={() => {
                handleAddItemToCart();
              }}
            >
              <BoldMediumText>Adicionar ao carrinho</BoldMediumText>
            </MainButton>
          )}
          <SecondaryButton
            sx={{ marginTop: 2 }}
            onClick={() => {
              handleBuyNow();
            }}
          >
            <BoldMediumText color={`${COLORS.white} !important`}>
              Comprar agora
            </BoldMediumText>
          </SecondaryButton>
          <UnderlinedButton
            sx={{
              justifyContent: "center",
              marginTop: 2,
            }}
            onClick={() => navigate(`/checkout/offer-case/${caseItem.caseId}`)}
          >
            <RedeemIcon />
            <BoldMediumText color={`${COLORS.black} !important`}>
              Oferecer como prenda
            </BoldMediumText>
          </UnderlinedButton>
        </>
      ) : (
        <MainButton disabled sx={{ opacity: "0.9 !important" }}>
          <BoldMediumText>Brevemente</BoldMediumText>
        </MainButton>
      )}
    </CaseCardToPurchaseWrapper>
  );
}

export default CaseCardToPurchase;
